import get from 'lodash/get';
import PageAttributesStore from '../../stores/account/modules/PageAttributesStore';
import PaymentService from '../../services/PaymentService';
import { ACTION_NAMES, APP_LOG_TYPE, tracking } from 'services/Tracking';
import { PAYMENT_PAYPAL } from '../../constants/shared';

export default {
    namespaced: true,

    state: {
        recurly: null,
        recurlyPublicKey: null,
        payPal: null,
        paymentMethod: null,
        reqCounter: 0,
        orderId: null,
        confirmationBaseUrl: null,
        showPickerConfirmationBaseUrl: null,
        coupon: null,
        giftToken: null,
        giftAmount: null,
        currencySymbol: null,
        currencyCode: null,
    },

    getters: {
        confirmationUrl: ({ orderId, confirmationBaseUrl }) => {
            return PaymentService.confirmationUrl(orderId, confirmationBaseUrl);
        },

        showPickerConfirmationUrl: ({ orderId, confirmationBaseUrl }) => {
            return PaymentService.showPickerConfirmationUrl(orderId, confirmationBaseUrl);
        },
        paymentRoute: (state, getters, rootState) => {
            return rootState.serverData.enhancedPaymentSummaryEnabled
                ? 'PAYMENT_SUMMARY'
                : 'PAYMENT';
        },
    },

    mutations: {
        setRecurly(state, recurly) {
            state.recurly = recurly;
        },

        setRecurlyPublicKey(state, recurlyPublicKey) {
            state.recurlyPublicKey = recurlyPublicKey;
        },

        setPayPal(state, gatewayCode) {
            if (state.recurly) {
                let payPalOpts = {
                    display: { displayName: 'Paramount+' },
                };
                if (gatewayCode) {
                    payPalOpts = { ...payPalOpts, gatewayCode };
                }
                state.payPal = recurly.PayPal(payPalOpts);
            }
        },

        setPaymentMethod(state, incomingValue) {
            state.paymentMethod = incomingValue;
        },

        setOrderId(state, orderId) {
            state.orderId = orderId;
        },

        setGiftCard(state, obj) {
            state.giftToken = obj.token;
            state.giftAmount = obj.amount;
        },

        setCurrencySymbol(state, symbol) {
            state.currencySymbol = symbol;
        },

        setCurrencyCode(state, code) {
            state.currencyCode = code;
        },
    },

    actions: {
        loadStore({ commit }, json) {
            commit('setRecurlyPublicKey', json?.recurlyPublicKey);
        },

        loadServerData({ dispatch }) {
            /*
             ** This action is fired by the account store, and is only used by
             ** edit version of component
             */
            return dispatch('pageAttributes/loadServerData');
        },

        setRecurlyConfig(context, serverData = {}) {
            const currency = serverData.plans
                ? get(serverData, 'plans[0].currency', 'USD')
                : get(serverData, 'currentSubscription.currency', 'USD');
            context.state.recurly.configure({
                currency,
                publicKey: serverData.recurlyPublicKey,
                risk: {
                    threeDSecure: {
                        preflightDeviceDataCollector: serverData.threeDSecure || false,
                    },
                },
            });
        },

        insertRecurlyScript(context, serverData = {}) {
            return new Promise((resolve, reject) => {
                let tag = document.createElement('script');

                tag.src = '//js.recurly.com/v4/recurly.js';
                tag.async = true;
                tag.onload = () => {
                    const limit = 5;
                    let attemptCount = 0;
                    let recurlyTimeout = setInterval(() => {
                        if (attemptCount < limit) {
                            if (window.recurly) {
                                tracking.trackAction(ACTION_NAMES.APP_LOG, {
                                    appLogText: 'recurly script timeout callback',
                                    appLogType: APP_LOG_TYPE.RECURLY,
                                    appLogCode: 'recurly loaded succesfully',
                                    appLogAttemptCount: attemptCount + 1,
                                });

                                context.commit('setRecurly', window.recurly);
                                context.dispatch('setRecurlyConfig', serverData);
                                context.dispatch('setCurrencySymbolCode', window.recurly);
                                resolve();
                                clearInterval(recurlyTimeout);
                            } else {
                                attemptCount++;
                            }
                        } else {
                            tracking.trackAction(ACTION_NAMES.APP_LOG, {
                                appLogText: 'recurly script timeout callback',
                                appLogType: APP_LOG_TYPE.RECURLY,
                                appLogCode: 'recurly not set',
                            });
                            reject({
                                reason: 'recurly not set',
                            });
                            clearInterval(recurlyTimeout);
                        }
                    }, 1000); // TODO: test timing to see if still needed
                };
                tag.onerror = (err) => {
                    tracking.trackAction(ACTION_NAMES.APP_LOG, {
                        appLogText: 'recurly tag error',
                        appLogType: APP_LOG_TYPE.RECURLY,
                        appLogCode: 'tag.onerror',
                    });
                    reject();
                };

                document.body.appendChild(tag);
            });
        },

        setCurrencySymbolCode(context, recurly) {
            const { currencySymbol, currencyCode } = recurly.Pricing.Subscription();
            let newCurrencySymbol = this.state.serverData.currencySymbol ?? currencySymbol;

            context.commit('setCurrencySymbol', newCurrencySymbol);
            context.commit('setCurrencyCode', currencyCode);
        },

        /**
         * @param context
         * @param form (form element with required recurly payment fields)
         *
         * @return {Promise<any>}
         */
        retrieveRecurlyToken(context, form) {
            return new Promise((resolve, reject) => {
                let recurly = context.state.recurly;
                context.commit('setPaymentMethod', null);

                if (!recurly) {
                    reject({
                        reason: 'recurly not set',
                    });
                } else {
                    recurly.token(form, (err, token) => {
                        if (err) {
                            reject({
                                reason: 'recurly.token error',
                                error: err,
                            });
                        } else {
                            resolve(token);
                        }
                    });
                }
            });
        },

        retrieveRecurlyPayPalToken(context, paypalGateway = false) {
            return new Promise((resolve, reject) => {
                if (!context.state.payPal) {
                    context.commit('setPayPal', paypalGateway);
                }

                let payPal = context.state.payPal;
                context.commit('setPaymentMethod', PAYMENT_PAYPAL);

                if (!payPal) {
                    reject({ reason: 'failed to set payPal' });
                } else {
                    payPal.start();

                    payPal.on('token', (token) => {
                        resolve(token);
                    });

                    payPal.on('error', (err) => {
                        reject({
                            reason: 'payPal.on error fired',
                            error: err,
                        });
                    });

                    // don't see cancel button, might never fire
                    payPal.on('cancel', (arg) => {
                        reject({
                            reason: 'payPal.on cancel fired',
                            arg: arg,
                        });
                    });
                }
            });
        },

        /**
         * @param context
         * @param token {object}
         * @param buttonEvent {object}
         *
         * @return {Promise<any>}
         */
        processPayment(
            context,
            {
                token,
                buttonEvent,
                plan,
                couponCode,
                tk_trp,
                resultActionTokenId = null,
                gateway = null,
                aarpUserId = null,
                recaptchaAction = '',
            },
        ) {
            return new Promise((resolve, reject) => {
                let isValidToken = token && typeof token === 'object' && token.id;

                if (isValidToken) {
                    let postParams = {
                        token: token.id,
                        m: buttonEvent.clientX.toString() + buttonEvent.clientY.toString(),
                        i: context.state.reqCounter,
                        productType: plan.planType,
                        productCode: plan.code,
                        couponCode: couponCode,
                        tk_trp: tk_trp,
                    };

                    if (recaptchaAction !== '') {
                        postParams.recaptchaAction = recaptchaAction;
                    }

                    if (resultActionTokenId) {
                        postParams = {
                            ...postParams,
                            resultActionTokenId,
                        };
                    }

                    if (gateway) {
                        postParams = {
                            ...postParams,
                            gateway,
                        };
                    }

                    if (aarpUserId) {
                        postParams = {
                            ...postParams,
                            aarpUserId,
                        };
                    }

                    if (context.state.paymentMethod) {
                        postParams.paymentMethod = context.state.paymentMethod;
                    }

                    PaymentService.postPayment(postParams)
                        .then((val) => {
                            context.commit('setOrderId', val.order_id);

                            resolve(val);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                } else {
                    reject({
                        reason: 'missing or invalid recurly/paypal token',
                        token: token,
                    });
                }
            });
        },

        processEditPayment(context, data) {
            let postParams = data;
            if (context.state.paymentMethod) {
                postParams.paymentMethod = context.state.paymentMethod;
            }
            return PaymentService.postPaymentUpdate(postParams);
        },

        promiseResolveDataRejectError(context, [obj, callback]) {
            return new Promise((resolve, reject) => {
                callback(obj).then(resolve).catch(reject);
            });
        },

        processCoupon(context, coupon) {
            return context.dispatch('promiseResolveDataRejectError', [
                coupon,
                PaymentService.postCoupon.bind(PaymentService),
            ]);
        },

        processGiftToken(context, token) {
            return context.dispatch('promiseResolveDataRejectError', [
                token,
                PaymentService.postGiftToken.bind(PaymentService),
            ]);
        },

        processGiftPurchase(context, token) {
            return context.dispatch('promiseResolveDataRejectError', [
                token,
                PaymentService.postGiftPurchase.bind(PaymentService),
            ]);
        },

        processGiftCode(context, token) {
            return context.dispatch('promiseResolveDataRejectError', [
                token,
                PaymentService.postGiftCode.bind(PaymentService),
            ]);
        },

        addGift(context, obj) {
            context.commit('setGiftCard', obj);
        },

        updateShortCode(context, obj) {
            return context.dispatch('promiseResolveDataRejectError', [
                obj,
                PaymentService.postUpdateShortCode.bind(PaymentService),
            ]);
        },
    },

    modules: {
        pageAttributes: PageAttributesStore,
    },
};
