import { render, staticRenderFns } from "./SharedPaymentComponent.vue?vue&type=template&id=0ed724e7"
import script from "./SharedPaymentComponent.vue?vue&type=script&lang=js"
export * from "./SharedPaymentComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_css-loader@3.6.0_lodash@4.17.21_prettier@3.3.3_r_lc7rzfbtzoo3e6ftvzdfsguzmy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports