import Vuex from 'vuex';

import Domain from 'helpers/Domain';
import ProfileStore from 'apps/userProfile/stores/modules/ProfileStore';
import Feature from 'aa/helpers/featureConstants';
import FlowStore from 'aa/vue/stores/modules/FlowStore';
import PaymentStore from 'aa/vue/stores/modules/PaymentStore';
import TrackingStore from 'aa/vue/stores/modules/TrackingStore';
import PlanStore from 'aa/vue/stores/modules/PlanStore';
import MultiSubPlanStore from 'aa/vue/stores/modules/MultiSubPlanStore';
import PageAttributesStore from 'aa/vue/stores/modules/PageAttributesStore';
import ShowsPickerStore from 'apps/showsPicker/stores/ShowsPickerStore';
import { AA_PURCHASE } from 'helpers/featureConstants';
import UserStore from 'aa/vue/stores/modules/UserStore';
import VariantStore from 'aa/vue/stores/modules/VariantStore';
import ModalStore from 'aa/vue/stores/modules/ModalStore';
import FeatureFlagsStore from 'apps/featureFlags/stores/FeatureFlagsStore';
import ParentalControlsStore from 'vue/stores/modules/ParentalControlsStore';
import {
    AUTHSUITE_ENABLED,
    SHOWTIME_BANNER_ENABLED,
    CAMPAIGN_SERVICE_ENABLED,
    FeatureKeys,
} from 'aa/helpers/featureConstants';

// CBSCOMSUB-4696
import NotificationBarStore from 'aa/vue/stores/modules/NotificationBarStore';
import PaymentService from 'aa/vue/services/PaymentService';
import PartnerStore from './modules/Partner/PartnerStore';
// remove when the CAPTCHA EPIC is completed
import { loadAuthSuiteStore } from 'services/AuthSuite/helpers';
import { HOME_URL } from 'aa/vue/constants/aaRoutes';
import SheerIDStore from 'aa/vue/stores/modules/SheerIDStore';
//

import Product from 'aa/vue/models/Product';

export default function () {
    const serverData = document.getElementById('app-config')
        ? JSON.parse(document.getElementById('app-config').innerHTML)
        : false;
    return new Vuex.Store({
        state: {
            count: 0,
            token: null,
            promo: null,
            isCMSPromo: false,
            navFrom: null,
            imageHost: null,
            featureFlags: {},
            localeDictionary: {},
            userMessage: {},
            serverData: {}, // does this need to be here?
            autoPromoChecked: false,
            deltaBranchJourneyKey: '',
        },

        mutations: {
            setToken(state, token) {
                state.token = token;
            },
            setPromo(state, promo) {
                state.promo = promo;
            },
            setIsCMSPromo(state, isCMSPromo) {
                state.isCMSPromo = isCMSPromo;
            },
            setAutoPromoChecked(state, hasBeenChecked) {
                state.autoPromoChecked = hasBeenChecked;
            },
            setNavFrom(state, from) {
                state.navFrom = from;
            },
            setImageHost(state, imageHost) {
                state.imageHost = imageHost;
            },
            setFeatureFlags(state, featureFlags) {
                if (featureFlags && typeof featureFlags === 'object') {
                    state.featureFlags = featureFlags;
                }
            },
            setLocaleDictionary(state, localeDictionary) {
                state.localeDictionary = localeDictionary;
            },
            setServerData(state, payload) {
                state.serverData = payload;
            },
            setUserMessage(state, payload) {
                state.userMessage = payload;
            },
            setDeltaBranchJourneyKey(state, branchKey) {
                state.deltaBranchJourneyKey = branchKey;
            },
            setCampaignOfferCoupon(state, noPromo) {
                if (CBS.Registry.Features[CAMPAIGN_SERVICE_ENABLED]) {
                    let campaignPromo = state.plan?.selectedPlan?.offerCouponCode;

                    if (noPromo) {
                        state.promo = null;
                    } else {
                        // if no campaign promo, fallback to param promo
                        state.promo = campaignPromo || state.promo || null;
                    }
                }
            },
        },

        getters: {
            getLocaleDictionary: (state) => state.localeDictionary,

            cmsImageBaseURL: (state) => {
                return state.imageHost + '/base/';
            },

            // CBSCOMSUB-4696
            getServerDataByKey: (state) => (key) => {
                if (!state.serverData) return null;

                if (key in state.serverData) {
                    return state.serverData[key];
                }

                return null;
            },

            featureIsActive: (state) => (feature) => {
                return state.featureFlags?.[feature] === true;
            },

            canBundleShowtime: (state, getters) => {
                return Boolean(
                    getters.featureIsActive('showtime_bundle') && !getters['flow/isEdu'],
                );
            },

            /**
             * @param state
             * @param getters
             * @returns {*|boolean}
             */
            bundleShowtime: (state, getters) => {
                if (CBS.Registry.Features.showtime_integration_enabled === true) {
                    return false;
                }
                return Boolean(getters.canBundleShowtime && state.plan.addonShowtime);
            },
            getProductParamsForTracking: (state, getters) => {
                const plan = serverData.multiSubPlanPicker
                    ? getters['plan/getSelectedPlan'] // from multiSubPlanStore
                    : getters['plan/getCurrentPlan']; // from planStore

                if (plan) {
                    let trackingParams = {
                        purchaseProductName: plan.titleForTracking,
                        productOfferPeriod: plan.trialString,
                        productPricingPlan: plan.planType,
                        purchasePrice: plan.rawPrice,
                        partnerBundle: plan.addOnCodes?.[0] ?? '',
                        pickPlanType: plan.titleForTracking,
                        showtimeBundleRawPrice: plan.showtimeBundleRawPrice,
                        pickPlanSku: plan.recurlyCode,
                        promoOffer: getters['plan/getPromoOfferStringForTracking'],
                        productDiscount: plan?.couponString,
                        purchasePromoCode: plan?.couponDiscount?.code,
                    };

                    return trackingParams;
                }

                return {};
            },

            getPaymentPageProductParamsForTracking: (state, getters) => {
                const plan = serverData.multiSubPlanPicker
                    ? getters['plan/getSelectedPlan'] // from multiSubPlanStore
                    : getters['plan/getCurrentPlan']; // from planStore

                if (plan) {
                    let trackingParamsPaymentPage = {
                        purchaseEventBillingStart: '1',
                        purchaseProduct: plan.recurlyCode,
                        purchaseQuantity: '1',
                        purchaseCategory: plan.titleForTracking,
                    };

                    return Object.assign(
                        getters.getProductParamsForTracking,
                        trackingParamsPaymentPage,
                    );
                }
                return {};
            },

            getRedirectUrlForResellers: (state, getters) => (userState) => {
                let userData = userState ? userState : state.user;
                let source = userData.svod.user_package.source,
                    status = userData.svod.user_package.status,
                    isCancelled = status === 'ACTIVE_CANCELLED',
                    partner = getters['flow/type'],
                    partnersWithMessage = ['walmartplus'];

                if (partnersWithMessage.includes(partner) && source !== partner) {
                    return isCancelled
                        ? '/account/'
                        : '/account/?notification=partner-subscribed&partner=' + partner + '';
                } else {
                    return `${CBS.Registry.region.prefix || ''}${HOME_URL}`;
                }
            },

            getTrackingParamsForGift: (state) => {
                const args = {};

                if (state.user.isExSubscriber || state.user.isSubscriber) {
                    args.giftCardRedeemExistingSub = '1';
                    args.pageType = 'svod_gift';
                    args.userType = state.user.svod.status;
                } else {
                    args.userRegType = 'gift';
                }

                return args;
            },

            getTrackingParamsForMVPDPartner: (state) => {
                return {
                    authPartnerId: state.authSuite?.selectedProviderName,
                    mvpdPartnerId: state.authSuite?.selectedProviderCode,
                    mvpdPartnerName: state.authSuite?.selectedProviderName,
                };
            },

            getTrackingParamsForSignUp: (state, getters) => {
                const args = {
                    pageType: 'svod_signup',
                };

                Object.assign(args, getters['flow/getTrackingActivationInfo']);

                if (!Domain.isDomestic()) {
                    if (state?.partner?.subscriptionDetails?.partnerCode) {
                        args.activationShortCode = state.partner.subscriptionDetails.partnerCode;
                    }
                }

                if (getters['flow/isGift']) {
                    Object.assign(args, getters['getTrackingParamsForGift']);
                }

                if (state.authSuite?.mvpdLoginEventBound) {
                    Object.assign(args, getters['getTrackingParamsForMVPDPartner']);
                }

                return args;
            },

            isShowtimeBannerEnabled: (state, getters) => {
                return getters['featureIsActive'](SHOWTIME_BANNER_ENABLED) === true;
            },
            getDeltaBranchJourneyKey(state) {
                return state.deltaBranchJourneyKey;
            },

            isPartnerBundle: (state, getters) => {
                return Feature.partnerBundleEnabled() && getters['flow/isCatan'];
            },
        },

        actions: {
            loadServerData({ dispatch, commit }, json) {
                if (json.flow) {
                    dispatch('flow/loadStore', json.flow);
                }

                dispatch('pageAttributes/setPageAttributes', json.pageAttributes || {});

                if (!json.multiSubPlanPicker) {
                    // plan type - force annual to show up first if flow says so
                    dispatch('plan/setPlanType', {
                        planType:
                            json && json.flow && json.flow.forceAnnual
                                ? Product.TYPE_ANNUAL
                                : Product.TYPE_MONTHLY,
                    });
                }

                if (json.variant) {
                    dispatch('variant/setVariant', json.variant);
                }

                if (json.plans && !json.multiSubPlanPicker) {
                    dispatch('plan/setPlans', json.plans);
                }

                commit('setFeatureFlags', json.featureFlag);
                commit('user/parseUserData', json.user);
                commit('setServerData', json);

                loadAuthSuiteStore(this, {
                    ...json.authSuiteConfig,
                    regionBaseUrl: json.regionBaseUrl,
                });

                if (json.user?.isLoggedIn) {
                    dispatch('flow/didCompleteSignIn');
                }

                if (json.featureFlag?.aa_purchase && !json.multiSubPlanPicker) {
                    dispatch('plan/loadStore', {
                        planType: json?.pageAttributes?.default_to_annual
                            ? Product.TYPE_ANNUAL
                            : Product.TYPE_MONTHLY,
                        plans: json.plans,
                    });
                }

                if (json?.recurlyPublicKey) {
                    dispatch('payment/loadStore', {
                        recurlyPublicKey: json.recurlyPublicKey,
                    });
                }

                if (json.multiSubPlanPicker && json.plans) {
                    // Change to setPlans when backend has been set up
                    dispatch('plan/setPlans', json.plans);
                }

                if (json.multiSubPlanPicker && json.plans) {
                    dispatch('plan/setAttributes', json.multiSubPlanAttributes);
                }
            },
            getPromo({ commit, rootState }) {
                return new Promise((resolve, reject) => {
                    PaymentService.getPromo(rootState.user.statusCode)
                        .then((result) => {
                            const promo = result?.promo_code;
                            const isCMSPromo = result?.isCMSPromo ?? false;
                            if (promo) {
                                commit('setPromo', promo);
                                commit('setIsCMSPromo', isCMSPromo);
                            }
                            resolve();
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            },
            async updateCoupon({ commit }, couponCode) {
                if (typeof couponCode === 'string') {
                    couponCode = couponCode.trim();
                }

                commit('setPromo', couponCode);
            },
        },

        // namespaces defined here
        modules: {
            user: UserStore,
            profile: ProfileStore,
            payment: PaymentStore,
            tracking: TrackingStore,
            flow: FlowStore,
            pageAttributes: PageAttributesStore,
            plan: serverData.multiSubPlanPicker ? MultiSubPlanStore : PlanStore,
            showspicker: ShowsPickerStore,
            variant: VariantStore,
            partner: PartnerStore,
            parentalControls: ParentalControlsStore,
            // CBSCOMSUB-4696
            notification: NotificationBarStore,
            modal: ModalStore,
            featureFlags: FeatureFlagsStore,
            sheerid: SheerIDStore,
        },
    });
}
