<template>
    <section class="sign-in-showtime-migrated">
        <BackgroundPicture :backgroundImageSet="backgroundImageSet"></BackgroundPicture>
        <img v-if="logoSrc" class="logo-pplus-showtime" :src="logoSrc" :alt="logoAlt" />
        <div class="padded-container">
            <h2>{{ pageAttributes.heading }}</h2>
            <h3>{{ pageAttributes.sub_heading }}</h3>
        </div>

        <SignIn
            :partnerRecaptchaAction="recaptchaAction"
            :display-heading="false"
            :pre-populated-fields="prePopulatedFields"
            :showtime-migrated="true"
            :success-callback="onLoginSuccess"></SignIn>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import SignIn from 'aa/vue/components/SignIn';
    import BackgroundPicture from 'aa/vue/atoms/BackgroundPicture';

    export default {
        name: 'SignInShowtimeMigrated',
        components: { BackgroundPicture, SignIn },
        data() {
            return {
                recaptchaAction: 'FORM_PARTNER_SIGN_IN'
            };
        },
        computed: {
            backgroundImageSet() {
                return {
                    landscape: this.pageAttributeToImageSource(
                        this.pageAttributes.primary_background_landscape,
                    ),
                    portrait: this.pageAttributeToImageSource(
                        this.pageAttributes.primary_background_portrait,
                    ),
                };
            },

            logoSrc() {
                const pageAttributeImgPath = this.pageAttributes.showtime_bundle_logo;

                if (!pageAttributeImgPath) {
                    return null;
                }

                return this.pageAttributeToImageSource(pageAttributeImgPath);
            },

            logoAlt() {
                return this.pageAttributes.showtime_bundle_logo_alt || '';
            },

            prePopulatedFields() {
                return {
                    email: {
                        value: this.$store.state.user.profile?.email,
                    },
                };
            },

            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),
        },

        methods: {
            onLoginSuccess() {
                if (this.$store.state.user.isSubscriber) {
                    window.location.assign('/user-profile/whos-watching/');
                } else {
                    window.location.assign('/');
                }
            },
        },

        beforeCreate() {
            document.body.style.marginTop = 0;
        },

        destroyed() {
            document.body.style.marginTop = null;
        },
    };
</script>
