<template>
    <section class="sign-in-noggin">
        <BackgroundPicture
            v-if="backgroundImageSet"
            :backgroundImageSet="backgroundImageSet"></BackgroundPicture>
        <img v-if="logoSrc" class="logo-pplus-noggin" :src="logoSrc" :alt="logoAlt" />
        <div class="padded-container">
            <h2>{{ pageAttributes.heading }}</h2>
            <h3>{{ pageAttributes.sub_heading }}</h3>
        </div>

        <SignIn
            :partnerRecaptchaAction="recaptchaAction"
            :display-heading="false" 
            :success-callback="onLoginSuccess"></SignIn>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import SignIn from 'aa/vue/components/SignIn';
    import BackgroundPicture from 'aa/vue/atoms/BackgroundPicture';
    import OAuthMixin from 'aa/vue/plugins/oAuth/OAuthMixin';

    export default {
        name: 'SignInNoggin',
        components: { BackgroundPicture, SignIn },
        mixins: [OAuthMixin],

        data() {
            return {
                recaptchaAction: 'FORM_PARTNER_SIGN_IN'
            };
        },

        mounted() {
            // Using querySelectorAll to find elements matching the selector
            const secondColumnItem = document.querySelectorAll(
                '.cbs-text-input.span-cols:nth-child(2)',
            )[0];

            const disclaimer = document.createElement('p');
            // Add a class to the disclaimer element
            disclaimer.classList.add('disclaimer');

            disclaimer.innerHTML = this.pageAttributes.noggin_signin_disclaimer;

            secondColumnItem.append(disclaimer);
        },

        computed: {
            backgroundImageSet() {
                return {
                    landscape: this.pageAttributeToImageSource(
                        this.pageAttributes.primary_background_landscape,
                    ),
                    portrait: this.pageAttributeToImageSource(
                        this.pageAttributes.primary_background_portrait,
                    ),
                };
            },

            logoSrc() {
                const pageAttributeImgPath = this.pageAttributes.noggin_bundle_logo;

                if (!pageAttributeImgPath) {
                    return null;
                }

                return this.pageAttributeToImageSource(pageAttributeImgPath);
            },

            logoAlt() {
                return this.pageAttributes.noggin_bundle_logo_alt || '';
            },

            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),
        },

        methods: {
            onLoginSuccess() {
                if (this.$store.state.user.isSubscriber) {
                    this.$store
                        .dispatch('user/setUserData')
                        .then((val) => {
                            this.authorize();
                        })
                        .catch((err) => {});
                } else {
                    this.onAuthorizationError();
                }
            },

            onAuthorizationError() {
                window.location.href =
                    this.$route.query.redirect_uri +
                    '?error=UserNotEntitled&state=' +
                    this.$route.query.state;
            },
        },

        beforeCreate() {
            document.body.style.marginTop = 0;
        },

        destroyed() {
            document.body.style.marginTop = null;
        },
    };
</script>
